import { BiGift, BiHourglass, BiShoppingBag, BiSortAZ, BiUser } from "react-icons/bi";
import { UserRoles } from "./global";

export interface MenuInterface {
  icon: React.FC;
  title: string;
  link: string;
  pathname: string;
  roles?: UserRoles[];
  subMenus?: MenuInterface[];
}
export const MENUS: MenuInterface[] = [
  {
    icon: BiHourglass,
    title: "Tombola",
    link: "/tombola",
    pathname: "tombola",
    roles: [],
  },
  {
    icon: BiUser,
    title: "Clients",
    link: "/client",
    pathname: "client",
    roles: [],
  },
  {
    icon: BiSortAZ,
    title: "Codes",
    link: "/code",
    pathname: "code",
    roles: [],
  },
  {
    icon: BiShoppingBag,
    title: "Lots",
    link: "/lot",
    pathname: "lot",
    roles: [],
  },
  {
    icon: BiGift,
    title: "Gagants",
    link: "/winner",
    pathname: "winner",
    roles: [],
  },
];
