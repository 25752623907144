import CardTable from "../components/card/CardTable";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";

export default function ClientPage() {
  /**
   * Hooks
   * */
  const { baseApiUrl, title, queryKey, pagination, setPagination } =
    useHeaderFunctionPage({ baseApiUrl: "client", title: "Client" });

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Nom",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Téléphone",
      key: "phone_number",
      dataIndex: "phone_number",
    },
    {
      title: "Points",
      key: "total_points",
      dataIndex: "total_points",
    },
    {
      title: "Ville",
      key: "town",
      dataIndex: "town",
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.date).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
    </>
  );
}
