import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Card from "../components/card/Card";
import { Form } from "../components/form/Form";
import { SubmitButton } from "../components/button/CustomButton";
import { InputText } from "../components/form/Input";
import { HTTPStatusResponse } from "../config/global";
import { APP_API_URL } from "../config/api";
import { useMutate } from "../hooks/useMutations";
import "../assets/styles/tombola.css";
import { ColumnInterface } from "../components/table/typings";
import moment from "moment";
import Table from "../components/table/Table";
import { useState } from "react";
import { Select2 } from "../components/form/Select";

export default function TombolaPage() {
  /**
   * Hooks
   */
  const [results, setResults] = useState([]);
  const [animateBall, setAnimateBall] = useState(false);

  /**
   * Form
   */
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(APP_API_URL + `tombola`, "post");

  /**
   * Function
   */
  const handleSubmitForm = (values: any) => {
    setResults([]);
    setAnimateBall(true);
    mutateAsync({
      lot: values.lot,
      type: values.type.value,
      number_winner: values.number_winner,
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        toast.success("Tirage éffectué !");
        setResults(response.data.data);
        setAnimateBall(false);
      }
    });
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Nom du client",
      key: "client",
      dataIndex: "client",
    },
    {
      title: "Numéro de téléphone",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Date",
      key: "date",
      render: (element: any) => (
        <>{moment(element.date).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  /**
   * UI
   */

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <Card title={"Tombola"}>
          <div className="mx-auto px-4 border border-gray-200 rounded-full w-[400px] h-[400px] border-b-[100px] border-t-[#fff] border-t-[25px] relative flex items-center">
            <div
              className={`rounded-full h-[400px] p-10 relative w-full ${
                animateBall ? "animate" : ""
              }`}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number) => (
                <span key={value} className="loader-tombola">
                  {" "}
                  <span>{value}</span>
                </span>
              ))}
            </div>
          </div>
        </Card>
        <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
          <Card
            title="Lancer la tombola"
            extras={[
              <div className="flex items-center gap-4" key={"select"}>
                <Select2
                  placeholder="Selectionner le type de tombola"
                  control={control}
                  name={"type"}
                  error={errors?.type}
                  register={register}
                  textKeyItem={"type"}
                  valueKeyItem={"value"}
                  items={[
                    {
                      type: "HEBDOMADAIRE",
                      value: "WEEKLY",
                    },
                    {
                      type: "MENSUELLE",
                      value: "MONTHLY",
                    },
                  ]}
                  label="Type "
                />
              </div>,
              <SubmitButton
                value="Démarrer"
                loading={isLoading}
                key="submit"
              />,
            ]}
          >
            <div className={"mb-10"}>
              <InputText
                name={"lot"}
                label="Lot à gagner"
                placeholder="Saisir le lot à gagner"
                register={register}
              />
            </div>
            <div className={"mb-10"}>
              <InputText
                placeholder={"Entrer le nombre de gagnants"}
                label={"Nombre de gagnants"}
                register={register}
                error={errors?.number_winner}
                name={"number_winner"}
              />
            </div>
            <Table columns={columns} loading={isLoading} datas={results} />
          </Card>
        </Form>
      </div>
    </>
  );
}
