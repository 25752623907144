import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./context/auth/AuthProvider";
import RequireAuth from "./middleware/RequireAuth";
import ManageRoute from "./middleware/ManageRoute";
import Main from "./components/layout/Main";
import SignWithAccessToken from "./pages/auth/SignWithAccessToken";
import SignOut from "./pages/auth/SignOut";
import ServerError from "./pages/error/500";
import LayoutProvider from "./context/layout/LayoutProvider";
import ClientPage from "./pages/ClientPage";
import CodePage from "./pages/CodePage";
import TombolaPage from "./pages/TombolaPage";
import LotPage from "./pages/LotPage";
import GainPage from "./pages/GainPage";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LayoutProvider>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route element={<Main />}>
                <Route path={"tombola"} element={<TombolaPage />} />
                <Route path={"client"} element={<ClientPage />} />
                <Route path={"code"} element={<CodePage />} />
                <Route path={"winner"} element={<GainPage />} />
                <Route path={"lot"} element={<LotPage />} />
              </Route>
              <Route path="logout" element={<SignOut />} />
            </Route>
            <Route path="auth/login" element={<SignWithAccessToken />} />
            <Route path="500" element={<ServerError />} />
            <Route path="*" element={<ManageRoute />} />
          </Routes>
        </LayoutProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
