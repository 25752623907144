import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { HTTPStatusResponse } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import SimpleButton from "../components/button/SimpleButton";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { BiSolidDownload } from "react-icons/bi";
import { formatText } from "../utils";
import { BadgeRounded } from "../components/badge/Badge";

export default function CodePage() {
  /**
   * Hooks
   * */
  const {
    baseApiUrl,
    title,
    queryKey,
    titleForm,
    setTitleForm,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "code", title: "Code" });

  /**
   * Form
   */
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  const { data: realDatas } = useQueries(
    APP_API_URL + `${baseApiUrl}s?paginate=0`,
    ["codes-not-paginate"],
    {
      refetchOnWindowFocus: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  /**
   * Function
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({ number_code: parseInt(values.number_code) }).then(
      (response: any) => {
        if (response && response.data.status === HTTPStatusResponse.OK) {
          reset();
          toast.success("Code généré !");
          setOpenModal(false);
        }
      }
    );
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Génerer des codes");
    setOpenModal(true);
    reset();
  };

  const handleExportInExcel = ({
    contentData,
    fileName,
  }: {
    contentData: any;
    fileName: string;
  }) => {
    const sheetData = contentData.map((data: any) => {
      return { code: data.num_code };
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Numéro de code",
      key: "num_code",
      render: (element: any) => <>{formatText(element.num_code)}</>,
    },
    {
      title: "Status",
      key: "win_date",
      render: (element: any) => (
        <BadgeRounded
          className={
            element.win_date ? "badge-soft-danger" : "badge-soft-success"
          }
          text={element.win_date ? "utilisé" : "disponible"}
        />
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.date).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            onClick={handleAddElement}
            value={"Générer"}
          />,
          <SimpleButton
            key={"export"}
            onClick={() =>
              handleExportInExcel({
                contentData: realDatas?.data.data,
                fileName: "Code",
              })
            }
            className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
          >
            <BiSolidDownload className="mr-2" /> <span>Exporter</span>
          </SimpleButton>,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
        className="w-full mx-auto md:w-[50%] sm:w-[80%]"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <InputText
              placeholder={"Entrer le nombre de code à générer"}
              label={"Code à générer"}
              register={register}
              error={errors?.number_code}
              name={"number_code"}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
