import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CardTable from "../components/card/CardTable";
import { InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { HTTPStatusResponse } from "../config/global";
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import { useState } from "react";
import { BiSolidDownload } from "react-icons/bi";
import SimpleButton from "../components/button/SimpleButton";
import useFileUpload from "../components/form/useFileUpload";
import { AddButton } from "../components/button/CustomButton";
import { BadgeRounded } from "../components/badge/Badge";

export default function LotPage() {
  /**
   * Hooks
   * */
  const {
    baseApiUrl,
    title,
    queryKey,
    titleForm,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
    setTitleForm,
  } = useHeaderFunctionPage({ baseApiUrl: "lot", title: "Lots" });
  const [openImportModal, setOpenImportModal] = useState(false);

  const { file, FileUploadView, reset: resetFileUpload } = useFileUpload({});

  /**
   * Form
   */
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { handleSubmit: handleSubmitImport } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
    queryKey
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: mutateImportData, isLoading: importDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-import`,
      "post",
      queryKey,
      {
        "Content-Type": "multipart/form-data",
      }
    );

  /**
   * Function
   */
  const handleSubmitAddForm = (values: any) => {
    storeData(values).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        reset();
        toast.success("Lot enregistré !");
        setOpenModal(false);
      }
    });
  };

  const handleSubmitImportForm = () => {
    mutateImportData({ file: file }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        reset();
        toast.success("Lots enregistrés !");
        setOpenImportModal(false);
        resetFileUpload();
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    resetFileUpload();
    setOpenModal(false);
    setOpenImportModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Enregistrer un lot");
    setOpenModal(true);
    reset();
  };
  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Libellé du lot",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Points à atteindre",
      key: "points_to_reach",
      dataIndex: "points_to_reach",
      render: (element: any) => (
        <BadgeRounded
          className={"badge-soft-secondary"}
          text={element.points_to_reach}
        />
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (element: any) => (
        <>{moment(element.date).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton onClick={handleAddElement} key={"add"} value="Créer"/>,
          // <SimpleButton
          //   key={"import"}
          //   onClick={() => setOpenImportModal(true)}
          //   className="bg-soft-secondary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center"
          // >
          //   <BiSolidDownload className="mr-2" /> <span>Importer</span>
          // </SimpleButton>,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
        className="w-full mx-auto sm:w-[40%]"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <InputText
              placeholder={"Entrer le nom du lot"}
              label={"Libellé"}
              register={register}
              error={errors?.name}
              name={"name"}
            />
          </div>
        </div>
      </ModalForm>

      <ModalForm
        title={"Charger le fichier des lots "}
        isLoading={importDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmitImport(handleSubmitImportForm)}
        open={openImportModal}
      >
        <p className="text-red-500 mb-4">
          Le fichier doit contenir 2 colonnes respectivement :{" "}
          <b className="font-extrabold">code</b>,
          <b className="font-extrabold">lot</b>
        </p>
        <FileUploadView
          accept={
            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          name={"file"}
        />
      </ModalForm>
    </>
  );
}
